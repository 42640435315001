import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Color } from "../../../../utils/color";

import { Breadcrumbs } from "../../../../components/breadcrumbs/breadcrumbs";
import { EnLayout } from "../../../../components/layout/enLayout";

const EnItem001Page = () => {
  return (
    <EnLayout
      title="PRODUCT | LIME"
      jpUrl="/product/category/item001/"
      enUrl="/en/product/category/item001/"
    >
      <Breadcrumbs>
        <Link to="/en/">HOME</Link>
        <span>/</span>
        <Link to="/en/product/">PRODUCT</Link>
        <span>/</span>
        <Link to="/en/product/category/">CATEGORY</Link>
        <span>/</span>
        <Link to="/en/product/category/item001/">ITEM001</Link>
        <span>/</span>
      </Breadcrumbs>
      <SSection>
        <p>EN ITEM001</p>
      </SSection>
    </EnLayout>
  );
};

export default EnItem001Page;

const SSection = styled.section`
  background-color: ${Color.light};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
